@import "../../styles/theme-colors.scss";

.modal-finbox {
  .ant-modal-content {
    padding: 2px;
    // height: 90vh;
  }
}

.dragger {
  height: 200px;
  // width: 500px;
  // margin: 10px;
}

.or {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-bank-Statements-div {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  &.v2 {
    align-content: start;
  }
}

.bank-Statements-view {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  border: 2px dashed #00000050;
  border-radius: 20px;
  p {
    font-size: 16px;
  }
  button {
    background-color: $primary;
    border-radius: 50px !important;
  }
}

.bank-Statements-btn-next-view {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  gap: 20px;
  button {
    background-color: $primary;
    width: 100%;
    max-width: 200px;
  }
  .ant-btn-default {
    background-color: transparent;
    color: $secondary;
    border-color: $secondary;
  }
}

.razorpay-bank-Statements-btn-next-view {
  .ant-btn-primary{    
    background: rgba(19, 100, 241, 1) !important;
    color:#fff;
    border:none;
    font-weight: bold;
  }
  .ant-btn-default {
    background: none;
    border:1px solid rgba(19, 100, 241, 1);
    color:rgba(19, 100, 241, 1);
    font-weight: bold;
  }
}

.list-item {
  padding: 0px !important;
  padding-top: 15px !important;
  border: none !important;
}

.item-statements {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px dashed #d9d9d9;
  padding: 15px;
  border-radius: 10px;
}

.loader-view {
  position: absolute;
  inset: 0;
  // height: 160vh;
  z-index: 11;
  background-color: black;
  opacity: 0.7;
  display: grid;
  align-content: center;
  .ant-spin {
    .ant-spin-text {
      font-size: 20px;
      margin-top: 10px;
    }
  }
  
  &.razorpay {
    .ant-spin-dot-item {
      background-color: white;
    }
    .ant-spin {
      .ant-spin-text {
        color: white !important;
      }
    }
  }
}

.div-UploadView {
  font-size: 16px;
  line-height: 1.5714285714285714;
  list-style: none;
  background: rgba(0, 0, 0, 0.02);
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  padding-block: 20px;
  height: auto !important;
}



