.label-verified {
    position: absolute;
    top: 0px;
    // top: -25px;
    // right: 0;
    right: 30px;
    font-size: 13px !important;
    color: #20A74E;
    padding: 0px 2px;
    align-items: center;
    display: flex;
    img {
        margin-right: 3px;
        width: 15px !important;
        height: 15px !important;
    }
}

.label-verficiation-pending {
    position: absolute;
    top: 0px;
    // top: -25px;
    // right: 0;
    right: 30px;
    font-size: 11px !important;
    color: #E12121;
    border: 1.5px solid #E12121;
    padding: 0px 2px;
    border-radius: 5px;
}

.rc-input-number-input{
    border-color: #909397 !important;
    border-inline-end-width: 1px !important;
    padding: 7px 11px !important;
    font-size: 16px !important;
    line-height: 1.4 !important;
    border-radius: 8px !important;
    width: 100%;
    width: 93%;
    border: 1px solid #909397;
}

.rc-input-number-input:hover{
    border-color: #4096ff !important;
    border-inline-end-width: 1px !important;
    padding: 7px 11px !important;
    font-size: 16px !important;
    line-height: 1.4 !important;
    border-radius: 8px !important;
    width: 93%;
}

.rc-input-number-input{
    border-color: #ff4d4f;
    border: 1px solid #ff4d4f;
}