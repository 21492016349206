.label-verified {
    position: absolute;
    top: 0px;
    // top: -25px;
    // right: 0;
    right: 30px;
    font-size: 13px !important;
    color: #20A74E;
    padding: 0px 2px;
    align-items: center;
    display: flex;
    img {
        margin-right: 3px;
        width: 15px !important;
        height: 15px !important;
    }
}

.label-verficiation-pending {
    position: absolute;
    top: 0px;
    // top: -25px;
    // right: 0;
    right: 30px;
    font-size: 11px !important;
    color: #E12121;
    border: 1.5px solid #E12121;
    padding: 0px 2px;
    border-radius: 5px;
}

.ant-form-item-label {
    padding: 0px !important;
}

.ant-form-item .ant-form-item-label>label::after{
    content: none !important;
}

.razorPay_input{
    background-color:  rgba(25, 40, 57, 1);
    color: #fff  !important;
    border:1px solid #585151 !important;
    .ant-input,.ant-input-lg::-webkit-input-placeholder{
        background-color:  rgba(25, 40, 57, 1);
        color: #fff !important;
      }
  }