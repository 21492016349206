.w-100-p {
  width: 100%;
}

.w-400 {
  width: 400px;
}

.w-350 {
  width: 350px;
}

.min-w-350 {
  min-width: 350px;
}

.max-w-400 {
  max-width: 400px;
}

.mi-auto {
  margin-inline: auto;
}

.mt-20 {
  margin-top: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.razorPay_mb-20 {
  color: white
}

.mb-0 {
  margin-bottom: 0px;
}

.ml-6 {
  margin-left: 6px;
}

.pi-15 {
  padding-inline: 15px;
}

.pi-20 {
  padding-inline: 20px;
}

.dn {
  display: "none";
}

.df {
  display: "flex";
}

.gap-20 {
  gap: 20px;
}

.ta-center,
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.jc-center,
.text-justify {
  justify-content: center;
}

.icon-text {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}

img[src=""] {
  display: none;
}

img[src="complementorLogoUrl"] {
  display: none;
}

.choices__item.choices__item--selectable {
  padding-right: 35px;
}

.upload-wrapper {
  .ant-upload-select {
    width: 100%;
    height: 100px;
    margin: 14px 0px;

    img {
      margin-right: 10px;
      height: 30px;
      width: 25px;
    }

    span {
      display: flex;
      width: 100%;
      height: 100%;
      text-align: center;
      align-items: center;
      justify-content: center;
      color: #273896;
      font-size: 16px;
      border: dashed 1px #273896;
      background: rgba(39, 56, 150, 0.13);
      cursor: pointer;
    }
  }

  .razorpay_upload-wrapper {

    .ant-upload,
    .ant-upload-select {

      span {
        color: #fff !important;
        background: transparent;
        cursor: pointer;
      }
    }
  }

  .ant-upload-list {
    padding-bottom: 15px;

    .ant-upload-list-item {
      margin-bottom: 10px;
    }
  }
}



/*Custom Styles*/
.ant-card {
  .ant-card-body {
    padding: 10px;
    // margin-bottom: 5px;
  }
}

.stepper-wrapper {
  font-family: Arial;
  font-size: 12px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stepper-wrapper_razorPay {
  font-family: Arial;
  font-size: 12px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  color: white
}

.razorPay_login-form {

  .ant-input,
  .ant-input-lg {
    background-color: rgba(25, 40, 57, 1);
    color: #fff;
  }
}

.razorPay-uploadDoc-text {
  color: #fff !important;
}

.razorPay-resendOtp {
  font-weight: bold;
  color: rgba(117, 163, 255, 1) !important;
}

.razorPay-uploadText {
  color: white !important;
}

.custom-select {
  width: 100%;

  .ant-select-selector {
    height: 39px !important;
    border-radius: 11px !important;
    border: 1px solid #d9d9d9 !important;

    .ant-select-selection-search {
      input {
        height: 39px !important;
      }
    }
  }

  .ant-select-selection-item {
    line-height: 39px !important;
  }

  .ant-select-selection-placeholder {
    line-height: 39px !important;
  }
}
.razorpay-upload-wrapper {

  .ant-upload,
  .ant-upload-select {
      background-color: rgba(25, 40, 57, 1) !important;
      color: #fff !important;
      border: 1px dashed lightgray;

  }

  .ant-upload-list-item-name {
      color: #fff !important;
  }
}

.g-recaptcha {margin: 0 auto;display: table;}



.app-btn-primary-bg {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-inline: 10px;
  background-color: #0C6EB6 !important;
  color: white !important;
  min-width: 80px;
  justify-content: center;
  border-radius: 6px;
  svg {
    path {
      stroke: white;
    }
  }
  &:hover {
    background-color: #0C6EB6DD !important;
  }
  &:disabled {
    background-color: #0C6EB6AA !important;
  }
}

.app-btn-secondary-border {
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1.4px solid #c71c8e;
  padding-inline: 10px;
  min-width: 80px;
  border-radius: 6px;
  justify-content: center;
  color: #c71c8e;
  svg {
    path {
      stroke: #c71c8e;
    }
  }
  &:hover, 
  &:focus {
    color: #c71c8e;
    border-color: #c71c8e;
  }
  &:hover {
    background-color: #00000011;
  }
}