@import "../../styles/theme-colors.scss";

.bg {
  position: absolute;
  inset: 0;
  background: $primary;
  opacity: 0.78;
  height: 100%;
}

.bg_razorPay {
  background:  rgba(25, 40, 57, 1);
  opacity:1;
}


.logo-view {
  width: 100%;
  display: flex;
  // justify-content: flex-end;
  justify-content: space-between;
  .logo {
    width: 115px;
    height: 115px;
    // margin: 20px;
    margin-inline: 20px;
    // margin-block: 5px;
  }
}

.product-features {
  padding: 30px;
  padding-right: 50px;
  .title-product-features {
    color: white;
    margin-bottom: 0px;
  }
  .footer-border {
    background-color: white;
    height: 4px;
    width: 80px;
    &.h-1 {
      height: 1px;
      background-color: rgba(203, 213, 226, 1);
      margin-top: 10px;
    }
    &.v2 {
      margin-top: 15px;
      height: 1px;
      width: 55px;
    }
  }
}

.list {
  color: white;
  font-size: 20px;
  list-style-type: none;
  padding: 0px;
  li {
    margin-top: 30px;
  }
}

.custome-step {
  margin-top: 25px;
  .ant-steps-item {
    .ant-steps-item-container {
      .ant-steps-item-tail {
        padding: 0px;
        padding-top: 12px !important;
        &::after {
          background-color: white !important;
          margin-left: 0px !important;
        }
      }
      .ant-steps-item-icon {
        .ant-steps-icon {
          background-color: white;
          display: flex;
          height: 20px;
          width: 20px;
          padding: 4px;
          border-radius: 10px;
          div {
            display: flex;
          }
        }
      }
      .ant-steps-item-content {
        min-height: 55px;
        .ant-steps-item-title {
          color: white;
          font-weight: 400;
          font-size: 18px;
        }
      }
    }
  }
  .ant-steps-item-active {
    .ant-steps-item-container {
      .ant-steps-item-tail::after {
        background-color: white !important;
      }
      .ant-steps-item-icon {
        .ant-steps-icon {
          div {
            margin-top: 1.4px;
            margin-left: 1.4px;
            height: 10px;
            width: 10px;
            border-radius: 10px;
            background-color: rgba(12, 110, 182, 1);
            img {
              display: none;
            }
          }
        }
      }
      .ant-steps-item-content {
        .ant-steps-item-title {
          font-weight: 500;
        }
      }
    }
  }
  &.ant-steps .ant-steps-item-finish {
    .ant-steps-item-container {
      .ant-steps-item-tail::after {
        background-color: white !important;
      }
      .ant-steps-item-icon {
        .ant-steps-icon {
          .anticon {
            color: rgba(12, 110, 182, 1);
          }
        }
      }
      .ant-steps-item-content {
        .ant-steps-item-title {
          font-weight: 500;
        }
      }
    }
  }
}