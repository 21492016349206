@import "../../styles/theme-colors.scss";

.person-details-view {
  border: 1px solid lightgray;
  // margin-inline: 40px;
  display: grid;
  border-radius: 8px;
  .person-details-title {
    padding-inline: 25px;
    padding-block: 10px;
    border-bottom: 1px solid lightgray;
  }
  .ant-list {
    margin-top: 10px;
  }
}

.applicant-type {
  display: flex;
  justify-content: center;
  .ant-form {
    width: 100%;
    max-width: 400px;
    .ant-form-item {
      width: 100%;
      max-width: 400px;
    }
  }
}

.btn-next-view {
  // display: "flex", justifyContent: "flex-end"
  display: flex;
  // justify-content: flex-end;
  margin-inline: 25px;
  button {
    background-color: $primary;
    width: 140px;
    margin-right: 15px;
    padding-inline: 100px;
  }
  .ant-btn-default {
    background-color: transparent;
    color: $secondary;
    border-color: $secondary;
  }
}

.razorpay_pan_msg {
  .ant-typography {
    color:#fff !important;
    margin-top: 20px;
    font-size: 16px;
  }
 .ant-form-item-required{
  color:#fff !important;
 }
}
 
