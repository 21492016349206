.types-document {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
}

.main-view {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 20px;

    &.razorPay-main-view {
        .anticon,.anticon-paper-clip{
            color:#fff !important;
          }    
    }
}


.loader-view {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: black;
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.upload-document-modal{
    padding-bottom: 20px;
}

.upload-btns{
    display: flex;
    align-items: center;
    justify-content: right;
    margin-top: 30px;
    button{
        margin: 0px 5px;
    }
}

// .razorPay-upload-box{
//     .ant-upload,.ant-upload-select{
//         border: 1px dotted rgb(39, 56, 150, 0.13);
        
//     }
    
// }
