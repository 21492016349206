@import "../../styles/theme-colors.scss";

.btn-otp {
  background-color: $secondary;
}

.btn-next {
  background-color: $primary;
  width: 100%;
}

.custom-radio {
  .ant-space-align-center {
    .ant-space-item {
      .ant-radio-button-wrapper-checked {
        color: #fff;
        border-color: #c71c8e !important;
      }

      .ant-radio-button-wrapper {
        box-shadow: none;
        border-radius: 30px;

        &:hover {
          background-color: #c71c8e;
          border-color: #c71c8e !important;
          color: #fff !important;
        }
      }

      .ant-radio-button-checked {
        background-color: #c71c8e;
        border-radius: 30px;
        color: #fff;
      }
    }
  }
}

.model-app-content {
  margin: 30px;
  .ant-modal-content {
    padding-right: 0px;
  }
  .app-content-body {
    height: calc(100vh - 180px);
    overflow: auto;
  }
}

.form-persion {
  .ant-form-item {
    margin-bottom: 20px;
  }
}

.razorPay_main_form {
  .ant-form-item-required,.ant-typography{
    color: #fff !important;
    a{
      color: #1677ff !important;
    }
  }
}

.razorpay_checkBox {
  .ant-checkbox-inner{
    background:transparent !important;
  }
}


