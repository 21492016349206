@import '../../styles/theme-colors.scss';

.main {
  margin-top: 30px;
  display: grid;
  justify-content: center;
  &.v2 {
    justify-content: left;
  }
}

.title {
  text-align: center;
  color: $primary !important;
  padding: 5px;
  &.v2 {
    justify-content: left;
  }
}

.razorpay-leadList-title {
  color:#fff !important;
}

.razor_pay_title {
  .ant-form-item-required,.ant-typography {
    color:white !important;
  }
}

.sub-title {
  margin-bottom: 30px;
}

.ant-steps-item-icon {
  background-color: purple !important;
  color: white !important;
}

.ant-steps-icon {
  // background-color: purple !important;
  color: white !important;
}

.ant-steps-item-process {
  // background-color: purple !important;
  color: purple !important;
}

::selection {
  color: #fff !important;
  background: purple !important;
}

.fa-solid fa-file-import {
  background-image: linear-gradient(to bottom, #35005e, #4e0069, #660073, #7e007c, #970084) !important;
}

.ant-steps-item-icon {
  fill: none !important;
  width: 20px !important;
  height: 20px !important;
  color: rgb(248, 247, 251) !important;
  // background: white !important;
  // border-color: #C71C8E 2px solid !important;
}

.ant-steps-item-tail {
  top: 8px !important;
  // background-color: gray !important;
  // left: 8px !important;
  // width: 2px !important;
}

.ant-steps-icon-dot {
  width: 20px !important;
  height: 20px !important;
  border: #C71C8E solid 2px!important;
}

.ant-steps-item-icon {
  width: 20px !important;
  height: 20px !important;
}

.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot,
.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background: #C71C8E 1px !important ;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-icon>.ant-steps-icon>.ant-steps-icon-dot::after {
  background-image: url("../../assets/check.svg") !important;
  background-repeat: no-repeat !important;
  height: 46px !important;
  width: 71px !important;
  background-position: center;
  content: "";
  margin: 0 auto !important;
  background-size: 14px !important;
}

.ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background: white !important;
}

:where(.css-dev-only-do-not-override-acm2ia).ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: #C71C8E !important;
}

.ant-steps-item-process .ant-steps-item-icon {
  background: transparent !important;
  border-color: transparent !important;
}

.ant-steps-item-icon {
  background: transparent !important;
  border-color: transparent !important;
}

.ant-steps-item-tail::after {
  // display: inline-block;
  width: 100%;
  height: 1px;
  background: rgb(218, 217, 217) !important;
  // border: #C71C8E;
  margin-bottom: 7px !important;
  margin-left: 17px !important;
  border-radius: 1px;
  // transition: background 0.3s;
  content: '';
}

.ant-steps-dot .ant-steps-item-title,
.ant-steps-dot.ant-steps-small .ant-steps-item-title {
  line-height: 1.5715;
  margin-left: 15px !important;
}

// .main_bg {
//   .ant-row,.css-dev-only-do-not-override-acm2ia{
//   background-color: rgba(12, 25, 39, 1);
//   }
// }

.main_screen_bg{
  background-color: white
}
.razorPay_right_screen_bg {
  background-color: rgba(12, 25, 39, 1);
}