.ant-list-pagination{
    display: flex;
    justify-content: center;
}
.leadlist-title{
    color: #bdbdbd;
}
.ant-list-item{
    padding: 0px;
}
.title-space{
    padding-top: 10px;
}

.lead-list-btn {
  display: flex;
}

.search-box  {
    background-color: rgba(25, 40, 57, 1);
    .ant-input,.ant-input-lg::-webkit-input-placeholder{
        background-color:  rgba(25, 40, 57, 1);
        color: #fff;
      }
}

.razorpay-mobileView-leadList {
        background: transparent !important;
        color: #fff !important;
}

.razorpay-movileView-leadPgination {
    .ant-pagination,.ant-pagination-mini,.ant-table-pagination,.ant-table-pagination-right,.ant-pagination-item{
        color: #fff;
      } 
      .ant-pagination-item-active{
        color: black;
      }
      .anticon,.anticon-double-right,.ant-pagination-item-link-icon{
        color:rgba(19, 100, 241, 1);
      }
      .ant-select,.ant-select-sm,.ant-pagination-options-size-changer,.ant-select-show-arrow{
          .ant-select-selector {
            background: none;
            color: white;
          }
      }
}

.razorpay-dropdown {
  .ant-dropdown-arrow::before {
    background-color: rgba(25, 40, 57, 1);  
  }
  .ant-dropdown-menu {
    background-color: rgba(25, 40, 57, 1);
    .ant-dropdown-menu-item {
      color: white;
    }
  }
}
