@import "./theme-colors.scss";

.ant-btn-text {
  border: none;
  background: transparent !important;
}

.ant-btn-primary {
  background-color: $primary !important;

  &.razorPay_btn {
    background: rgba(19, 100, 241, 1) !important;
    font-weight: bold;
  }
}

.ant-btn-primary:disabled {
  background-color: rgba(0, 0, 0, 0.04) !important;

  &.ant-btn-primary {
    font-weight: bold;
    border: none;
    &.RazorPay {
      background: rgba(19, 100, 241, 1) !important;
      color: #fff !important;
    }
  }
}

.ant-btn-default {
  &.razorPay_btn_cancel {
    background: rgba(227, 234, 243, 0.15);
    border: none;
    color: #fff;
    font-weight: bold;
  }
}


.razor_pay_modal {
  .ant-modal-content,
  .ant-modal-title {
    background-color: rgba(47, 66, 86, 1);
    color: #fff;
  }

  .ant-modal-close-x {
    color: #fff;
  }

  .ant-form-item-required {
    color: #fff !important;
  }

  .rc-input-number-input {
    background-color: rgba(12, 25, 39, 1);
    color: #fff;
  }

  .ant-btn-primary:disabled {
    background: rgba(19, 100, 241, 1) !important;
    color: #fff;
  }
}

.razorpay-upload-wrapper {

  .ant-upload,
  .ant-upload-select {
      background-color: rgba(25, 40, 57, 1) !important;
      color: #fff !important;
      border: 1px dashed lightgray;

  }

  .ant-upload-list-item-name {
      color: #fff !important;
  }
}

.razorPay_table {

  .ant-table,
  .ant-table-small,
  .ant-table-fixed-column,
  .ant-table-scroll-horizontal,
  .ant-table-has-fix-right,
  .ant-table-cell,
  .ant-table-cell-ellipsis,
  .ant-table-thead>tr>th {
    background: rgba(12, 25, 39, 1);
    color: #fff;
  }

  .ant-table-cell,
  .ant-table-cell-fix-right,
  .ant-table-cell-fix-right-first {
    background: rgba(12, 25, 39, 1);
  }

  .ant-table-tbody {
    >tr:hover>td {
      background: lightgray !important;
      color: black;
    }
  }

  .ant-pagination,
  .ant-pagination-mini,
  .ant-table-pagination,
  .ant-table-pagination-right,
  .ant-pagination-item {
    color: #fff;
  }

  .ant-pagination-item-active {
    color: black;
  }

  .anticon,
  .anticon-double-right,
  .ant-pagination-item-link-icon {
    color: rgba(19, 100, 241, 1);
  }

  .ant-select,
  .ant-select-sm,
  .ant-pagination-options-size-changer,
  .ant-select-show-arrow {
    .ant-select-selector {
      background: none;
      color: white;
    }
  }
}

.razorPay_input {
  background-color: rgba(25, 40, 57, 1);
  color: #fff !important;

  .ant-input,
  .ant-input-lg::-webkit-input-placeholder {
    background-color: rgba(25, 40, 57, 1);
    color: #fff !important;
  }

  .ant-form-item-explain-error {
    color: white;
  }
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: $secondary !important;
  background-color: $secondary !important;

  &.razorPay_radio_btn {
    border-color: rgba(19, 100, 241, 1) !important;
    background-color: rgba(19, 100, 241, 1) !important;
  }

}

.razorpay-mobileView-modal {

  .ant-modal-content,
  .ant-modal-title {
    background-color: rgba(47, 66, 86, 1);
    color: #fff;
  }

  .ant-select-selector {
    background-color: rgba(25, 40, 57, 1) !important;
  }

  .ant-select-selection-placeholder {
    color: #fff !important;
  }

  .ant-radio-wrapper {
    color: #fff !important;
  }
  .ant-divider-inner-text{
    color: #fff !important;
  }
  .anticon,
  .anticon-paper-clip {
    color: #fff !important;
  }
}