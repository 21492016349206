@import "../../styles/theme-colors.scss";

.row {
  margin: 0px;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.sr-only {
  display: none;
}

.btn-side-by-side {
  display: flex;
  .fieldset-body {
    display: flex;
    gap: 10px;
  }
}

.loan-application-title {
  div {
    display: flex;
    justify-content: center;
    input {
      text-align: center;
      font-weight: 400;
      font-size: 20px;
      color: white;
      background-color: #0c6eb6 !important;
      border: 0px;
      width: 320px;
      margin-bottom: -25px;
      margin-top: 10px;
      z-index: 10;
    }
  }
}

.border-offer {
  border: 3px solid #0c6eb6;
  border-radius: 10px;
  padding-block: 10px;
  justify-content: center;
  margin-inline: auto;
  padding: 10px;
  padding-top: 35px;
  @media (max-width: 768px) {
    .col-md-3 {
      width: 100%;
      padding-inline: 10px;
    }
  }
}

.remove-border-loan-eligiblity-title {
  div {
    width: 100%;
    color: green;
    text-align: center;
    border: 0px;
    background-color: transparent !important;
    font-size: 28px;
    font-weight: 400;
    // input {
    // 	text-align: center;
    // 	font-weight: 400;
    // 	font-size: 28px;
    // 	color: green;
    // 	background-color: transparent !important;
    // 	border: 0px;
    // }
    @media (max-width: 768px) {
      font-size: 16px;
      padding: 0px;
    }
  }
}

@media (max-width: 768px) {
  .mobile-view {
    display: none;
  }
  .logo-mobile {
    display: flex;
    justify-content: center;
    margin-block: 30px;
  }
  .personal-details {
    margin-block: 10px !important;
    // width: 95%;
  }
  .remove-border-loan-eligiblity-title {
    div {
      input {
        font-size: 16px;
      }
    }
  }
  .col-md-3 {
    // flex: 0 0 auto;
    width: 120px;
    padding: 0px;
  }
  .loan-application-title {
    div {
      input {
        font-size: 16px;
      }
    }
  }
  .col-md-6 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    // padding: 0px;
  }
  .border-offer {
    min-width: 300px;
    max-width: 550px;
  }
}

@media (min-width: 768px) {
  .col-md-1 {
    flex: 0 0 auto;
    width: 10.333333333%;
    padding: 0px;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667;
    padding: 0px;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 23%;
    padding: 0px;
  }
  .col-md-4 {
    flex: 0 0 auto;
    height: 100vh;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    height: 100vh;
    width: 35%;
  }
  .col-md-6 {
    display: flex;
    flex-direction: column;
    // gap: 10px;
    // padding: 0px;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 65%;
    height: 100vh;
    overflow: auto;
  }
  .logo-mobile {
    display: none;
  }
  .personal-details {
    margin: 10px;
    width: 85%;
    // width: 100%;
    margin-left: 70px !important;
  }
  .border-offer {
    width: 550px;
  }
}

.col-md-8 {
  display: flex;
  justify-content: center;
}

.col-md-12 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: inherit;
  align-content: center;
  padding: 0px;
}

.remove-border {
  width: 100%;
  div {
    input {
      background-color: transparent !important;
      border: 0px;
      padding-inline: 0px;
    }
  }
}

.btn-edit-loan-amount {
  button {
    background: url("../../assets/edit-pencil.svg");
  }
}

.btn-info-loan-amount {
  button {
    background: url("../../assets/info.svg");
  }
}

.btn-icons {
  button {
    background-position: center;
    background-repeat: no-repeat;
    border: none;
    width: 25px;
    height: 25px;
  }
  button:hover {
    background-color: lightgray;
  }
}

.border-persnol {
  border: 1px solid lightgray;
  border-radius: 10px;
  margin-inline: 0px;
  margin-bottom: 10px;
  justify-content: center;
}

.fieldset-loan-amount {
  fieldset {
    .fieldset-body {
      display: block;
    }
  }
}

.formio-error-wrapper {
  background-color: transparent;
}
.has-error > .help-block {
  display: none;
}

.alert-success {
  display: none;
}

.header-loan {
  text-align: center;
  color: $primary;
  padding-block: 30px;
}

.eligible-title {
  color: green;
}

.timeofStudy-view {
  .row {
    gap: 10px;
    .col {
      padding: 0px;
      width: 48%;
    }
  }
}

.btn-center {
  margin-inline: auto;
}

.back_btn {
  margin-top: 15px !important;
  background-color: transparent !important;
  border-color: $secondary !important;
  color: $secondary;
  width: 110px;
  margin-left: auto;
  :hover {
    margin-top: 15px !important;
    background-color: transparent !important;
    border-color: $secondary !important;
    color: $secondary;
  }
}

.next_btn {
  width: 110px;
  margin-top: 15px !important;
}

.remove-bg {
  .bg-light {
    background-color: transparent !important;
  }
}

.tandc-privacy-policy {
  cursor: pointer;
  background-color: transparent !important;
  color: $primary;
  border: 0px;
  :hover {
    background-color: transparent !important;
    color: $primary;
    border: 0px;
  }
}

.processingImg {
  background-image: url("../../assets/offer.svg");
  width: 300px;
  height: 300px;
  background-position: center;
  background-size: cover;
  margin-top: 20px;
}

.processingImg-1 {
  background-image: url("../../assets/processing.svg");
}

.offer-amount {
  .form-control {
    padding-right: 5px;
    font-size: 15px;
  }
  @media (max-width: 768px) {
    display: flex;
    gap: 20px;
  }
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 10px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 9px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
  width: 200px;
}

.stepper-item.active .step-counter {
  font-weight: bold;
  background-color: $secondary;
}

.stepper-item.completed .step-counter {
  background-color: $secondary;
  background-image: url("../../assets/check.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid $secondary;
  width: 100%;
  top: 10px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}

.stepper-item:last-child::after {
  content: none;
}

.terms-conditions {
  margin-top: 20px !important;
}

.alert-danger {
  display: none;
}

.fa-calendar:before {
  content: url("../../assets/calender.svg");
}

.req-loan-amo {
  .fieldset-body {
    display: flex !important;
  }
}

.razorPay-formIo {
  .col-form-label {
    color: #fff;
  }
  .mb-0 {
    color: #fff;
  }
  .card-header {
    border: 1px solid lightgrey;
  }
  .card,
  .card-body,
  .bg-light,
  .mb-3 {
    background: transparent;
    border: 1px solid lightgrey;
  }
  .form-control {
    background: rgba(25, 40, 57, 1) !important;
    color: #fff !important;
  }
  .input-group-text {
    background: rgba(25, 40, 57, 1);
    .fa fa-calendar {
      color: #fff;
    }
  }
  .field-required,
  .form-check-label {
    color: #fff;
  }
  .ant-collapse-header {
    color: #fff !important;
  }
  .row.formio-component.formio-component-columns.border-offer.formio-component-label-hidden {
    background: rgba(25, 40, 57, 1);
    border: 1px solid gray;
  }
  .formio-component-htmlelement {
    color: #fff !important;
  }

  .btn.btn-primary.btn-md.back_btn {
    border: 1px solid rgba(19, 100, 241, 1) !important;
    color: rgba(117, 163, 255, 1) !important;
    background: none;
    font-weight: bold;
  }

  .btn.btn-primary.btn-md.next_btn {
    background: rgba(19, 100, 241, 1) !important;
    font-weight: bold;
  }
  .loan-application-title {
    div {
      input {
        color: white;
        background-color: rgba(19, 100, 241, 1) !important;
      }
    }
  }
  .formio-editor-read-only-content {
    color: #fff;
  }
  .processingImg {
    display: none;
  }
  .personal-details {
    background: none !important;
  }
  .remove-border {
    div {
      input {
        background-color: transparent !important;
      }
    }
  }
  .btn-edit-loan-amount {
    button {
      background: url("../../assets/edit-pencil-blue.svg");
      background-repeat: no-repeat;
      margin-top: 4px;
    }
  }
  .choices__list--dropdown {
    background-color: rgba(25, 40, 57, 1);
    color: white;
    .choices__input {
      color: white;
      background-color: rgba(25, 40, 57, 1) !important;
    }
    .is-highlighted {
      background-color: rgb(12, 25, 39);
    }
  }
  .flatpickr-calendar {
    background-color: rgba(25, 40, 57, 1);
  }
}

.Forcar .formio-component-button {
   display: flex !important;
   justify-content: flex-start !important;
}

.Forcar{
  max-width: 85% !important; 
}
.formio-div-v2 {
  max-width: 400px;
  .col-md-12 {
    display: block;
  }
  .row {
    margin-bottom: 20px;
  }
  .form-control {
    height: 40px;
  }
  .formio-component-button {
    display: flex;
    justify-content: flex-end;
	.btn {
		padding-inline: 30px;
	}
  }
  .btn-multi {
	.fieldset-body {
		display: flex;
		gap: 10px;
		.back_btn {
			margin-top: 0px !important;
		}
	}
  }
}
