.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .large-404 {
    font-size: 120px;
    font-weight: bold;
    position: relative;
    color: grey; /* red color for 404 */
  }
  
  .shadow-404 {
    position: absolute;
    top: 0;
    
    left: 10px; /* Adjust this value to control the shadow's distance */
    color: white; /* Shadow color */
    font-size: 120px;
    font-weight: bold;
  }
  
  .error-card {
    margin-top: 12px;
    border: 2px solid black; /* Border color around "Error" */
    padding: 1px 70px;
    font-size: 30px;
    font-weight: bold;
    border-radius: 0px;
    margin-bottom:10px ;
  }