.bg {
    position: relative !important;
    inset: 0;
    background: #0C6EB6;
    opacity: 0.78;
    height: 100%;
}
.text-muted:before {
    color: #000;
    content: "+";
}