.checkBox-style {
    .ant-form-item {
        margin-bottom: 0 !important;
        .ant-form-item-row {
            display: grid !important;
            grid-auto-flow: column !important;
        }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: green;
        border-color: green !important;
    }

    .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
        background-color: green !important;
        border-color: green !important;
    }
}