@import "../../styles/theme-colors.scss";

.processing-center-view {
	// height: 100vh;
	height: calc(100vh - 20px);
	align-content: center;
	display: grid;
}

.main-processing {
	display: flex;
	justify-content: center;
	flex-direction: column;
	.image-processing {
		width: 100%;
		max-width: 300px;
	}
	h4 {
		width: 100%;
		max-width: 400px;
		color: green;
		margin: 0px !important;
		text-align: center;
	}
	.ant-list {
		margin-top: 10px;
		border: 2px solid #124DAA;
		border-radius: 10px;
		padding-top: 10px;
		width: 100%;
		max-width: 500px;
	}
}
.offerScreen {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	& .offerBtnSection {
		// margin-top: 5px;
		display: flex;
		justify-content: center;
		.next_btn {
			width: 200px !important;
			height: 60px !important;
			border-radius: 6px;
			margin: 10px;
			font-size: 16px;
			font-weight: 400;
			color: white;
			@media (max-width: 768px) {
				font-size: 16px;
			}
		}
		.back_btn {
			width: 187px !important;
			height: 60px !important;
			border-radius: 6px;
			margin: 10px;
			font-size: 18px;
			font-weight: 400;
			color: $secondary !important;
			@media (max-width: 768px) {
				font-size: 16px;
			}
		}
	}
	.offer-text {
		display: flex;
		justify-content: center;
		align-items: center;
		.offer-amount {
			font-weight: 600;
			font-size: 34px;
			@media (max-width: 768px) {
				font-size: 18px;
			}
		}
	}
}
.offerSubHeader {
	font-weight: 500;
	font-size: 17px;
	margin: 5px;
}
.offerMainTable {
	width: 100%;
	.ant-row {
		text-align: center;
		margin: 8px;
		padding: 5px;
		.ant-col {
			font-size: 14px;
			font-weight: 500;
		}
	}
	& .offerScreenDetails {
		.ant-row {
			border: 2px solid #124daa;
			border-radius: 8px;
			margin: 8px;
			padding: 5px;
			text-align: center;
			.ant-col {
				padding: 10px 8px 10px 8px;
				font-size: 16px !important;
				font-weight: 500;
				@media (max-width: 768px) {
					font-size: 15px !important;
				}
			}
		}
	}
	@media (max-width: 768px) {
		width: 100%;
	}
}
.offerFooterImages {
	display: flex;
	align-items: center;
	justify-content: center;
	.footerText {
		font-size: 20px;
		font-weight: 400;
		margin: 15px;
	}
	.footerImages {
		width: 700px;
		display: flex;
		justify-content: center;
	}
}

.razorpay-offer-default-btn {
	border: 1px solid rgba(19, 100, 241, 1) !important;
	color: rgba(117, 163, 255, 1) !important;
	background: none;
	font-weight: bold;
}
.buero-offer {
	font-size: 16px;
	margin: 10px;
	font-weight: 400;
	position: absolute;
	bottom: 0;
	@media (max-width: 768px) {
		font-size: 16px;
		margin: 10px;
		font-weight: 400;
		position: relative;
		// bottom: 0;
	}
}

.thankyou-msg {
	font-weight: normal !important;
	color: rgba(0, 0, 0, 0.80) !important;
	width: 355px !important;
}

.thankyou-center-view {
	// height: 100vh;
	height: calc(80vh - 20px);
	align-content: center;
	display: grid;
}

.close-btn {
width: 100px;
border:1px solid rgba(199, 28, 142, 1);
color:rgba(199, 28, 142, 1);
 &:hover,
 &:focus{
	border:1px solid rgba(199, 28, 142, 1) !important;
	color: rgba(199, 28, 142, 1) !important;
 }
}

.offer-lender-msg {
   display: flex;
   justify-content: center;
}
// .ant-space-item{
// 	width: 50%;
// 	text-align: center;
// }