.view-logo {
  display: flex;
  justify-content: center;
  padding-block: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
  img {
    height: 75px;
    object-fit: contain;
  }
  @media (max-width: 768px) {
    img {
      height: 60px;
    }
  }
}

.view-width-indiamart {
  // max-width: 1440px;
  // margin-inline: auto;
  // background-color: white;
}
.view-main {
  position: relative;
  display: flex;
  justify-content: center;
  .view-main-data {
    width: 75%;
    border: 1px solid rgb(0, 0, 0, 0.1);
    @media (max-width: 768px) {
      width: auto;
    }
    .view-formio {
      .view-formio-header {
        font-weight: 500;
        font-size: 34px;
        color: #271e58;
        display: flex;
        justify-content: center;
      }
      .view-formio-page {
        margin-top: 10px;
      }
      .view-footer-section {
        display: flex;
        justify-content: center;
        .footer-section {
          width: 80%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-bottom: 20px;
          .form-persion {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .otp-btn {
            width: 260px;
            margin-top: 0px !important;
            @media (max-width: 768px) {
              width: -webkit-fill-available;
            }
          }
          .otp-input {
            width: 260px;
            @media (max-width: 768px) {
              width: -webkit-fill-available;
            }
          }
          .btn-next {
            width: 260px;
            margin: 0px;
            @media (max-width: 768px) {
              width: -webkit-fill-available;
            }
          }
          .powered-by {
            display: none;
          }
          @media (max-width: 768px) {
            width: 100%;
            margin-top: 8px;
            margin-bottom: 10px;
            .powered-by {
              // border-top: 1px solid gray;
              border-top: 1px solid #00000010;
              display: flex;
              justify-content: center;
              // padding-top: 10px;
              margin-top: 5px;
              margin-inline: -20px;
              font-size: 16px;
              align-items: center;
              span {
                margin: 10px
              }
              img {
                height: 40px;
              }
            }
            .ant-typography {
              // text-align: center;
            }
          }
        }
        @media (max-width: 768px) {
          box-shadow: 0px -2px 10px gray;
          position: fixed;
          /* border: 0; */
          z-index: 10;
          bottom: 0px;
          background: white;
          left: 0;
        }
      }
    }
  }
}
.view-header-section {
  position: relative;
  gap: 20px;
  align-items: end;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    position: relative;
    gap: 20px;
    align-items: end;
    display: block;
    justify-content: center;
  }
}
.errorMsg {
  color: red;
  font-size: 14px;
  width: 100%;
  @media (max-width: 768px) {
    margin-left: 15px;
  }
  
}
.view-tarraki {
  // left: 50px;
  // bottom: -75px;
  position: relative;
  width: 75%;
  background-color: #124daa99;
  color: white;
  display: grid;
  padding-block: 20px;
  padding-inline: 40px;
  // border-radius: 0px 70px 0px 70px;
  gap: 10px;
  font-weight: 500;
  .title-tarraki {
    display: flex;
  }
  .tit {
    font-size: 30px;
  }
  .desc {
    font-size: 25px;
  }
  .border {
    height: 3px;
    width: 60px;
    background-color: white;
  }
  .verify {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid white;
    border-radius: 50px;
    width: fit-content;
    padding-right: 15px;
    margin-left: auto;
    font-size: 17px;
  }

  @media (max-width: 768px) {
    left: 0px;
    bottom: 0px;
    position: relative;
    width: auto;
    margin-inline: 20px;
    padding-block: 10px;
    padding-inline: 15px;
    // margin-top: -60px;
    border-radius: 0px 55px 0px 55px;
    .tit {
      font-size: 20px;
      margin-left: 10px;
    }
    .desc {
      font-size: 15px;
      margin-left: 10px;
    }
    .border {
      margin-left: 10px;
    }
    .verify {
      font-size: 12px;
      img {
        height: 25px;
      }
    }
  }
}

.view-apply {
  right: 50px;
  bottom: -75px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  //   height: 500px;
  //   width: 400px;
  border: 1px solid lightgray;
  padding: 20px;
  gap: 20px;
  .tit {
    color: #271e58;
    font-size: 34px;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    left: 0px;
    bottom: 0px;
    position: static;
    border: none;
    margin-top: 10px;
    .powered-by {
      display: none;
    }
  }
}

.view-features {
  margin-top: 100px;
  margin-bottom: 50px;
  display: grid;
  justify-items: center;
  gap: 10px;
  .tit {
    font-size: 34px;
    color: #271e58;
    font-weight: 500;
  }
  .border {
    height: 6px;
    width: 90px;
    background-color: #124daa;
  }
  .main-features {
    width: 100%;
    display: flex;
    justify-content: space-around;
    .features {
      padding: 20px;
      display: flex;
      gap: 10px;
      div {
        display: grid;
        .tite {
          font-size: 22px;
          color: #124daa;
          font-weight: 500;
        }
      }
    }
  }
  @media (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 10px;
    .border {
      display: none;
    }
    .main-features {
      flex-direction: column;
      div {
        .tite {
          font-size: 20px;
        }
      }
    }
  }
}

.view-footer-indiamart {
  display: flex;
  justify-content: space-between;
  background-color: #fbfafb;
  padding: 20px;
  width: 100%;
  .tc-pp {
    display: grid;
  }
  .desc {
    text-align: end;
    margin-right: 15px;
  }
  .powered-by {
    display: none;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    .support {
      display: flex;
      justify-content: center;
      margin-bottom: 8px;
    }
    .tc-pp {
      div {
        display: grid;
      }
    }
    .desc {
      display: none;
    }
    .powered-by {
      // border-top: 1px solid gray;
      border-top: 1px solid #00000010;
      display: flex;
      justify-content: center;
      padding-top: 10px;
      margin-top: 10px;
      margin-inline: -20px;
      font-size: 16px;
      align-items: center;
      span {
        margin: 10px
      }
    }
  }
}

.offer-indiamart {
  display: flex;
  background-image: linear-gradient(to right, #c8e8ff, #ffffff);
  .offer-view {
    flex: 1;
  }
  .processingImg {
    display: none;
  }
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-image: none;
    .offer-user {
      background-image: linear-gradient(to right, #c8e8ff, #ffffff);
      display: flex;
      justify-content: center;
      img {
        height: 200px;
      }
    }
    .offer-view {
      flex: 1;
      margin: 20px;
      // padding-inline: 20px;
      border: 1px solid rgba(0, 0, 0, 0.06);
      margin-top: -30px;
      background-color: white;
    }
  }
}
.Thank-you-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & .thank-you-image {
     & img {
      height: auto;
      width: auto;
      @media (max-width: 768px) {
        height: 400px;
        width: 400px;
      }

     }
  }
  & .thank-you-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 22px;
    font-weight: 400;
  }
}
.ant-form label {
  @media (max-width: 768px) {
    margin: 0px 7px 0px 7px !important;
  }
}
.indiaMartOtpField {
  @media (max-width: 768px) {
    width: -webkit-fill-available;
  }
}

.indiaMart {
  height: auto;
  position: unset;

  .powered-by-web {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      margin: 5px
    }
    @media (max-width: 768px) {
      display: none;
    }  
  }
  .formio-error-wrapper {
    padding: 0px !important;  
  }
  @media (max-width: 768px) {
    .lead-list-btn {
      width: 100% !important;
      div {
        width: auto !important;
        justify-content: center !important;
        align-items: center !important;
      }
    }
  }
  .btn-next {
    background-color: #124DAA !important;
  }
  .ant-btn-primary {
    background-color: #124DAA !important;
  }
  .remove-border-loan-eligiblity-title div {
    color: #124DAA
  }
  .offer-view {
    margin-top: 45px;
    margin-right: 20px;
    display: grid;
    @media (max-width: 768px) {
      height: 100%;
      margin-bottom: 50px; 
    }
  }
  .offer-user{
    width: 45%;
    @media (max-width: 768px) {
      width: auto;
    } 
  }
  .offerMainTable {
    width: 95%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .offerScreen .offerBtnSection {
    width: -webkit-fill-available;
  }
  .offerScreen .offerBtnSection .next_btn {
    height: 55px !important;
  }
  .offerScreen .offerBtnSection .back_btn {
    height: 55px !important;
  }
  .offerSubHeader {
    display: none;
  }
  .application-footer-div {
    justify-content: space-between !important;
    margin: 25px 50px 0px 50px  !important;
  }
  .application-footer-msg {
    display: block !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: green;
    border-color: green !important;
  }
  .form-control.is-invalid {
    background-image: none;
  }
  .form-control {
    height: 40px;
  }
}