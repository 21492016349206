@import '../../styles/theme-colors.scss';

.types-document {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
}

.doc-list {
    // display: table;
    width: 90%;
    padding-bottom: 30px;
    height: 530px !important;
    overflow: auto;

    ul {
        list-style: none;
        padding: 0px;
        margin: 0px;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: solid 1px #ededed;
            margin-bottom: 10px;
            padding-bottom: 10px;

            .doc-details {
                display: flex;
                align-items: center;

                svg {
                    font-size: 32px;
                    color: #273896;
                    margin-right: 5px;
                }

                span {
                    width: 170px;
                    font-size: 13px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    margin-left: 5px;

                    .type {
                        font-size: 12px;
                    }
                }
            }

            .doc-action {
                span {}

                span.remove {
                    color: #E12121;
                    cursor: pointer;

                    svg {
                        margin-right: 8px;
                        position: relative;
                        top: 3px;
                    }
                }
            }
        }

        li:last-child {
            border-bottom: none;
        }
    }
}

.btn-box {
    margin-bottom: 30px;
    margin-top: 5px;


    .ant-radio-button-wrapper {
        margin-right: 10px;
        border-radius: 30px;
        cursor: pointer;

        &::before {
            display: none;
        }
    }
}

.custome-list {
    .ant-list-item {
        padding: 0px;
    }
}

.custom-checkable {
    padding: 4px 10px;
    border: 1px solid #C71C8E;
    border-radius: 40px;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    margin-left: 0px;
    margin-top: 5px;
}

.custom-checkable.ant-tag-checkable-checked {
    background: #C71C8E;
}

.razorPay-custom-checkable.ant-tag {
    border: 1px solid rgba(19, 100, 241, 1);
    color: #fff !important;

}

.razorPay-custom-checkable.ant-tag-checkable-checked {
    color: #fff !important;
    background: rgba(19, 100, 241, 1) !important;

}

.razorPay-custom-checkable {
    :where(.css-dev-only-do-not-override-acm2ia).ant-tag-checkable:not(:where(.css-dev-only-do-not-override-acm2ia).ant-tag-checkable-checked):hover {
        color: white !important;
        background-color: rgba(19, 100, 241, 1) !important;
    }

    :where(.css-dev-only-do-not-override-acm2ia).ant-tag-checkable:not(:where(.css-dev-only-do-not-override-acm2ia).ant-tag-checkable-checked):active {
        color: white !important;
        background-color: rgba(19, 100, 241, 1) !important;
    }
}



.upload-area {
    .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
        border-color: $secondary !important;
        background-color: $secondary !important;
    }
}

.razorpay-upload-area {
    .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
        border-color: rgba(19, 100, 241, 1) !important;
        background-color: rgba(19, 100, 241, 1) !important;
    }
}

.custom-checkable {
    :where(.css-dev-only-do-not-override-acm2ia).ant-tag-checkable:not(:where(.css-dev-only-do-not-override-acm2ia).ant-tag-checkable-checked):hover {
        color: white !important;
        background-color: #C71C8E !important;
    }

    :where(.css-dev-only-do-not-override-acm2ia).ant-tag-checkable:not(:where(.css-dev-only-do-not-override-acm2ia).ant-tag-checkable-checked):active {
        color: white !important;
        background-color: #C71C8E !important;
    }
}

.close-button {
    background-color: white;
    color: $secondary;
    border-color: $secondary;
}

.close-button:hover {
    background-color: $secondary;
    color: white;
    border-color: $secondary;
}

.razorPay_title {
    &.ant-typography {
        color: #fff !important;
    }
}

.razorPay_panel {
    .ant-collapse-header-text {
        color: #fff !important;
    }

    background-color:rgba(25, 40, 57, 1) !important;
    color:#fff !important;

    .ant-collapse-content-box {
        background-color: rgba(25, 40, 57, 1) !important;

        .ant-typography {
            color: #fff !important;
        }
    }

    .anticon,
    .anticon-right,
    .ant-collapse-arrow {
        color: #fff !important;
    }

    .ant-radio-wrapper,
    .ant-radio-wrapper-checked {
        color: #fff !important;
    }

    .ant-select-selector {
        background-color: rgba(25, 40, 57, 1) !important;
        color: #fff !important;

        .ant-select-selection-placeholder {
            color: #fff !important;
        }
    }

    .ant-divider {
        color: #fff !important;
        //     &.ant-divider-horizontal,.ant-divider-with-text,.ant-divider-with-text-center{
        //     color: #fff !important;
        // }
    }

}

.razorpay-upload-wrapper {

    .ant-upload,
    .ant-upload-select {
        background-color: rgba(25, 40, 57, 1) !important;
        color: #fff !important;
        border: 1px dashed lightgray;

    }

    .ant-upload-list-item-name {
        color: #fff !important;
    }
}

.razorpay-divider::before,
.razorpay-divider::after {
    border-top: 1px solid #fff !important;
}

.razorPay_formBtn_close{
    border:1px solid rgba(19, 100, 241, 1)!important;
    color: rgba(117, 163, 255, 1) !important;
    background: none;
    font-weight: bold;
    width:115px;
    height: 32px;
  }
