.doc-collapse {
    margin-top: 20px;
    background-color: #fff;
    border: solid 1px #bfc4e1;
    border-radius: 6px;
    .ant-collapse-item {
      border-bottom: none;
      .ant-collapse-header {
        width: 100%;
        min-height: 44px;
        .ant-collapse-header-text {
          width: 100%;
        }
      }
      .ant-collapse-content-box {
        padding: 0px;
        
        .ant-row {
          padding-block: 6px;
          border-bottom: 1px solid #bfc4e1;
          .ant-form-item-row {
            border: none;
            // border-radius: 11px;
            .custom-input{
            margin: 5px;
            padding: 5px;
            border: 1px solid #bfc4e1;
            border-radius: 11px;
            }
          }
          .action-btns {
            margin-right: 5px;
            overflow: hidden;
            .ant-btn {
              background-color: transparent;
            }
          }
          .ant-btn .button-submit {
            color: #ffffff;
            background-color: #273896;
            border-color: #273896;
          }
          .custom-select .ant-select-selector {
            border-radius: 11px !important;
          }
          // .ant-btn {
          //   background-color: transparent;
          // }
        }
        .error {
          background-color: rgba(225, 33, 33, 0.14);
        }
      }
    }
  }
  .ant-popover.ant-popconfirm.ant-popover-placement-topRight.topRight-popup {
    left: 50% !important;
    right: 120px !important;
  }
  .doc-checkbox {
    justify-content: center;
    display: flex;
    flex-wrap: inherit;
    align-content: center;
  }
  .ant-select-selection-item {
     & .downloadBtn {
      display: none;
    }
  }

  .doc-category-tags {
    display: inline-block;
    width: 118px !important;
    height: 23px !important;
    background-color: #3991F6;
    color: #FFF;
    //font-family: Avenir LT Std;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 141%; /* 19.74px */
    letter-spacing: 0.2px;
    margin: 0px 5px;
    border-radius: 3px;
    text-align: center;
    padding: 2px;
    float:right;
}

.razorPay-upload-docList {
  .ant-collapse-header{
    background:rgba(25, 40, 57, 1) !important;
  }
  .ant-collapse-item,.ant-collapse-header-text{
    background:rgba(25, 40, 57, 1) !important;
    color: #fff !important;
  }
  .ant-collapse-content-box {
    background-color: rgba(25, 40, 57, 1) !important;

    .ant-typography {
        color: #fff !important;
    }
}
  .ant-row{
    color: #fff !important;
  }

  .ant-input-affix-wrapper,.ant-input-affix-wrapper-lg,.css-dev-only-do-not-override-acm2ia{
    background:rgb(25, 40, 57, 1) !important;
    color: #fff !important;
  }

  .ant-input,.ant-input-lg::-webkit-input-placeholder{
    color: #fff !important;
  }

  .ant-btn-primary,.ant-btn-lg{
    background-color: #3991F6 !important;
  }

  .ant-collapse-expand-icon{
    color: #fff !important;
  }

  .anticon,.anticon-close-circle{
    color:gray;
  }

}

.loader-view-document {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #fff;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-view-new {
  position: absolute;
  inset: 0;
  // height: 160vh;
  z-index: 11;
  background-color:rgb(25, 40, 57, 1);
  opacity: 0.7;
  display: grid;
  align-content: center;
  .ant-spin {
    .ant-spin-text {
      font-size: 20px;
    }
  }
}

.doc-header-msg {
  margin-bottom: 30px;
  margin-top: -15px;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
}