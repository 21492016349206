.model-document-upload {
  top: auto !important;
  width: 400px !important;
  bottom: 0 !important;
  right: 25px !important;
  position: absolute !important;
  .ant-modal-mask {
    background-color: rgb(0 0 0 / 0%);
  }
  .ant-modal-body {
    padding: 0px;
  }
  .ant-modal-content {
    border-radius: 8px !important;
    padding: 0px;
  }
  & .header {
    padding: 6px;
    padding-left: 14px;
    font-size: 1rem;
    background-color: black;
    color: white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .ant-modal-close {
    color: white;
  }
  & .header-title {
    font-size: 18px;
  }
  .ant-col {
    padding-block: 7px;
  }
  .document-content {
    padding: 0px 14px;
  }
  .doc-cols {
    display: flex;
    align-items: center;
  }
  .document-name {
    font-weight: 600;
    // line-break: anywhere;
  }
  .document-size {
    font-weight: 400;
    line-break: anywhere;
  }
  .loading-img {
    animation: spin 1s linear infinite;
  }
  @keyframes ckw {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
